import React, { useState } from "react";
import { navigate } from "@reach/router";
import { Form, Button } from "react-bootstrap"
import queryString from 'query-string';
import $ from 'jquery';

import {CustomLinks} from "../../common/utils";

const NewsSearch = (props) => {

    let query_params = queryString.parse(props.location.search);
    var srch_text = query_params.q ? query_params.q : '';

    const[search_text, setSearchText] = useState(srch_text ? srch_text : '');

    const handleChange = (e) =>{
        setSearchText(e.target.value);
    }

    const handleSubmit = () =>{
        search_text ? navigate(`${CustomLinks.insights}/?q=${search_text}`) 
        : navigate(`${CustomLinks.insights}`);

        $('html, body').animate({
            scrollTop: $(".features-section").offset().top-140
        }, 1000);
    }

    const resetSrch = () => {
        setSearchText('');
        navigate(`${CustomLinks.insights}`);

        $('html, body').animate({
            scrollTop: $(".features-section").offset().top-140
        }, 1000);
    }

    return (
        <React.Fragment>
            <Form action={`javascript:;`} onSubmit={handleSubmit}>
                <Form.Control type="text" name="q" placeholder="Search by title or content" value={search_text} onChange={handleChange} autoComplete={false} />
                <span className="clear_btn" onClick={() => resetSrch()}>X</span>
                <Button type="submit" className="btn-submit" variant="link"><i className="icon-search-white"></i></Button>
            </Form>
        </React.Fragment >
    );
};
export default NewsSearch;
