import React, {useState} from "react"
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal"
import dateFormat from "dateformat";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner  from '../components/Home/Banner/Banner.js'
import StaticPageBanner  from '../components/InnerPage/StaticPageBanner/StaticPageBanner'
import {AuctionApi} from "../components/common/utils"
import Loadable from "@loadable/component"

const Counter = Loadable(() => import ('../components/Home/Counter/Counter.js'))

const Advice = Loadable(() => import ('../components/Home/Advice/Advice'))

const Meet_The_Team = Loadable(() => import ('../components/InnerPage/Meet_The_Team/Meet_The_Team'))

const Approch = Loadable(() => import ('../components/Home/OurApproch/Approch'))

const News = Loadable(() => import ('../components/Home/News/News'))

const Client = Loadable(() => import ('../components/Home/OurClients/Clients'))
const Team = Loadable(() => import ('../components/Home/OurTeam/Team'))
const Started = Loadable(() => import ('../components/Home/Contact/Contact'))
const PopupMenu = Loadable(() => import ('../components/Header/Sections/PopupMenu'))
const OurServices = Loadable(() => import ('../components/PropertyDetails/Commercial/Commercial'))
const Reviews = Loadable(() => import ('../components/InnerPage/Reviews/Reviews'))
const ContactFormBottom = Loadable(() => import ('../components/FormBottom/ContactFormBottom'))
const CVFormBottom = Loadable(() => import ('../components/FormBottom/CVFormBottom'))
const InnerPageClients = Loadable(() => import ('../components/InnerPage/OurClients/Clients'))
const RelatedServices = Loadable(() => import ('../components/InnerPage/RelatedServices/RelatedServices'))
const ClientReviews = Loadable(() => import ('../components/InnerPage/ClientReviews/ClientReviews'))
const ContentSection = Loadable(() => import ('../components/InnerPage/ContentSection/ContentSection'))
const Breadcrumbs = Loadable(() => import ('../components/InnerPage/Breadcrumbs/Breadcrumbs'))
const News_Insights = Loadable(() => import ('../components/ListingPages/News_Insights/News_Insights'))
const Offices_GetInTouch = Loadable(() => import ('../components/ListingPages/GetInToch/GetInTouch'))
const People_Team = Loadable(() => import ('../components/ListingPages/OurTeam/Team'))
const Brands = Loadable(() => import ('../components/ListingPages/Brands/Brands'))
const AccordionModule = Loadable(() => import ('../components/InnerPage/Accordion/Accordion'))
const FeatureComponent = Loadable(() => import ('../components/InnerPage/FeatureProperties/FeatureComponent'))
const Vacancies = Loadable(() => import ('../components/InnerPage/Vacancies/Vacancies'))
const EmployeesSay = Loadable(() => import ('../components/InnerPage/Employees_Say/EmployeesSay'))
const AboutHistory = Loadable(() => import ('../components/PersonDetails/AboutHistory/AboutHistory'))
const FormsLayout = Loadable(() => import ('../components/FormsPage'))
const MemberForm = Loadable(() => import ('../components/forms/member-form'))
const AuctionTemplate = Loadable(() => import ('./auction-template'))
const FloatingNumber = Loadable(() => import ('../components/Auctions/FloatingNumber/FloatingNumber'))
const Testimonials = Loadable(() => import ('../components/Testimonials/Testimonials'))
const FloatingCTA = Loadable(() => import ('../components/Commercial/FloatingCTA'))

function CommonTemplate(props) {
  var GQLPage = props.GQLPage;
  var GQLModules = props.GQLModules;

  const [search, setSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [menu_heading, setMenuHeading] = useState(false);
  const [state, setState] = useState({showMenu: false});
  const [isOpen,setIsOpen] = useState(false);
  const [membDet,setMembDet] = useState('');
  const [popupType,setPopupType] = useState('');
  var today = new Date();
  var start_date = dateFormat(today,'yyyy-mm-dd');
  var past_end_date = parseInt(today.getFullYear() - 1) +"-"+ parseInt(today.getMonth() + 1) +"-"+ today.getDate();
  var future_end_date = parseInt(today.getFullYear() + 1) +"-"+ parseInt(today.getMonth() + 1) +"-"+ today.getDate();
  var available_end_date = parseInt(today.getFullYear()) +"-"+ parseInt(today.getMonth() + 1) +"-"+ today.getDate();
  const MemberPopup = (item, popupType) =>{
    setPopupType(popupType);
    setMembDet(item);
    setIsOpen(true);
  }
  const openHam = () => {
    setOpen(!open);
  };
  const openSearch = (menu_title) => {
    setSearch(!search);
    handlerClick();
    setMenuHeading(menu_title)
  };
  const closeSearch = () => {
    setSearch(!search);
    handlerClick();
  };

  const handlerClick = () => {
    setState({ ...state, showMenu: !state.showMenu })
    if (state.showMenu == true) {
        document.body.className = '';
    }
    else {
        document.body.classList.add("overflow-hidden");
    }
  }

  var right_side_module = GQLModules && GQLModules.filter(Module => Module.__typename === "GLSTRAPI_ComponentRightsideComponentsRightsideComponents");
  var counter_module = GQLModules && GQLModules.filter(Module => Module.__typename === "GLSTRAPI_ComponentComponentBannerBottom");

  return (
    GQLPage && 
    <Layout GQLPage={GQLPage} classNames={GQLPage?.Page_Class} closeSearch={closeSearch} handlerClick={handlerClick} menu_heading={menu_heading} search={search} open={open} popular_search_slug={GQLPage?.select_menu?.Primary_URL} >
        
        <SEO title={GQLPage?.Meta_Title} description={GQLPage?.Meta_Description} Robots={GQLPage?.select_menu?.Robots} image={GQLPage?.Banner_Image?.url} />
        
        <Helmet bodyAttributes={{ class: `body_cls` }}>

          {GQLPage?.select_menu?.Type === "Auctions" && 
            <script defer src="https://www.googletagmanager.com/gtag/js?id=AW-10810490027"></script>  
          }
          
        </Helmet>

        { GQLPage.Layout === "Form_Layout" ? 
          <FormsLayout GQLPage={GQLPage} location={props.location} />
        : (GQLPage.Layout === "Static_Page" ? 
         <StaticPageBanner GQLPage={GQLPage} Module={GQLModules.filter(Module => Module.__typename === "GLSTRAPI_ComponentComponentBannerCtAs")} openSearch={openSearch} openHam={openHam} search={search} open={open} location={props.location} /> 
        : GQLPage.Layout === "Default" && 
          <Banner GQLPage={GQLPage} CTA_Module={GQLModules.filter(Module => Module.__typename === "GLSTRAPI_ComponentComponentBannerCtAs")} Slider_Module={GQLModules.filter(Module => Module.__typename === "GLSTRAPI_ComponentComponentSlider")} openSearch={openSearch} openHam={openHam} search={search} open={open} api_link={`${AuctionApi.future}&startDate=${start_date}&endDate=${future_end_date}`} /> 
          )
        }
       { GQLPage.Layout === "Default" && props.location.pathname === "/commercial/commercial-property-valuation/" &&
          <FloatingCTA GQLPage={GQLPage}/>}

        {/* ======= Banner Bottom ======= */}
        {
          counter_module.length > 0 && counter_module.map((Module, i) =>{
            return(
              <Counter Module={Module} Layout={GQLPage.Layout} Menu_Link={GQLPage.select_menu} />
            )
          })
        }
        {/* ======= Banner Bottom ======= */}

        {(GQLPage.Layout === "Without_Banner" && (GQLPage.Alias !== "current-catalogue" && GQLPage.Alias !== "available-lots")) && <Breadcrumbs classNames={''} MenuLinks={GQLPage.select_menu}/>}        
        { 
            (GQLPage.Alias !== "current-catalogue" && GQLPage.Alias !== "available-lots") && 
            (GQLPage.Layout !== "Form_Layout" && ((props.GQLPage.Page_Content !== "" && props.GQLPage.Page_Content !== null) || GQLModules.filter(Module => Module.__typename === "GLSTRAPI_ComponentComponentFaqComponent").length > 0) && GQLModules.filter(Module => Module.__typename === "GLSTRAPI_ComponentComponentIntroComponent").length <= 0) && <ContentSection location={props.location} GQLPage={GQLPage} RightSideModule={right_side_module} Module={GQLModules.filter(Module => Module.__typename === "GLSTRAPI_ComponentComponentFaqComponent")} />
          
        }
        
        {
          GQLModules.map((Module, i) => {
            return(
              <>
                {/* {Module.__typename === "GLSTRAPI_ComponentComponentBannerBottom" && <Counter Module={Module}/> } */}
                
                {Module.__typename === "GLSTRAPI_ComponentComponentIntroComponent" && <Advice GQLPage={GQLPage} Module={Module} location={props.location} openSearch={openSearch} openHam={openHam} search={search} open={open} Menu_Link={GQLPage.select_menu}/> }

                {Module.__typename === "GLSTRAPI_ComponentComponentIntroComponent" && <Meet_The_Team GQLPage={GQLPage} Module={Module} location={props.location} MemberPopup={MemberPopup} /> }
                
                {(Module.__typename === "GLSTRAPI_ComponentComponentFaqComponent" && (GQLPage.Layout !== "Without_Banner" && props.GQLPage.Page_Content === "" && right_side_module.length === 0)) && <AccordionModule Module={Module} className={"mt-5"} pageName={GQLPage?.Pagename}/> }

                {Module.__typename === "GLSTRAPI_ComponentComponentHowWeGotHere" && <AboutHistory Module={Module} articleId={GQLPage?.id} ggfx_results={GQLPage?.ggfx_results}/> }

                {Module.__typename === "GLSTRAPI_ComponentComponentEmployeeSayComponent" && <EmployeesSay Module={Module} articleId={GQLPage?.id} ggfx_results={GQLPage?.ggfx_results}/> }

                {Module.__typename === "GLSTRAPI_ComponentComponentContentImageSection" && <Approch Module={Module} openSearch={openSearch} Menu_Link={GQLPage.select_menu}/> }

                {Module.__typename === "GLSTRAPI_ComponentComponentOurServicesComponent" && <OurServices Module={Module}/> }

                {Module.__typename === "GLSTRAPI_ComponentComponentClientsWeWorkWith" && <InnerPageClients Module={Module} articleId={GQLPage?.id} ggfx_results={GQLPage?.ggfx_results}/> }

                { Module.__typename === "GLSTRAPI_ComponentComponentReviewsModule" && <Testimonials Module={Module} /> }

                {/* {Module.__typename === "GLSTRAPI_ComponentComponentShowReviewsComponent" && <Reviews Module={Module} global_module={props.global_module}/> } */}

                {Module.__typename === "GLSTRAPI_ComponentComponentShowNewsInsights" && Module.Show_Hide && <News Module={Module} page={GQLPage}  Alias={GQLPage.Alias} /> }

                {Module.__typename === "GLSTRAPI_ComponentComponentThreeBlocksComponents" && <Client Module={Module} GQLPage={GQLPage}/> }

                {Module.__typename === "GLSTRAPI_ComponentComponentShowCustomerReviews" && <ClientReviews Module={Module} />  }

                {Module.__typename === "GLSTRAPI_ComponentComponentRelatedServices" && <RelatedServices Module={Module} /> }

                {Module.__typename === "GLSTRAPI_ComponentComponentOurPeopleComponent" && <Team Module={Module} Alias={GQLPage.Alias}/> }

                {Module.__typename === "GLSTRAPI_ComponentComponentLetGetStarted" && <Started Module={Module} Menu_Link={GQLPage.select_menu} /> }

                {Module.__typename === "GLSTRAPI_ComponentComponentFeaturedComponent" && <FeatureComponent Module={Module} Alias={GQLPage.Alias}/> }

                {Module.__typename === "GLSTRAPI_ComponentComponentVacanciesComponent" && <Vacancies /> }

                {Module.__typename === "GLSTRAPI_ComponentComponentShowContactFormModule" && <ContactFormBottom Module={Module} Alias={GQLPage.Alias} Menu_Link={GQLPage.select_menu} openSearch={openSearch} openHam={openHam} search={search} open={open} subject={"Contact Us"} /> }

                {Module.__typename === "GLSTRAPI_ComponentComponentCvFormComponent" && <CVFormBottom Module={Module} openSearch={openSearch} openHam={openHam} search={search} open={open}/> }

                {Module.__typename === "GLSTRAPI_ComponentModulesAuctionPhoneNumber" && <FloatingNumber  Module={Module} /> }

                {Module.__typename === "GLSTRAPI_ComponentComponentShowNewsListingPage" && <>
                    {Module.Choose_Listing_Page === "News_and_Insights" && <News_Insights location={props.location} alias={GQLPage.Alias} />}

                    {Module.Choose_Listing_Page === "Peoples" && <People_Team location={props.location} MemberPopup={MemberPopup} department={props?.department} />}

                    {Module.Choose_Listing_Page === "Offices" && <Offices_GetInTouch location={props.location} />}

                    {Module.Choose_Listing_Page === "Clients" && <Brands />}

                    {Module.Choose_Listing_Page === "Past_Auctions" && <AuctionTemplate auctionTag="past-auction"  auctionType="past" publishStatus="true" sort_order="auctionDate:desc" api_link={`${AuctionApi.past}&startDate=${past_end_date}&endDate=${start_date}`}/>}

                    {Module.Choose_Listing_Page === "Future_Auctions" && <AuctionTemplate auctionTag="future-auction"  auctionType="future" publishStatus="true" sort_order="auctionDate:asc" api_link={`${AuctionApi.future}&startDate=${start_date}&endDate=${future_end_date}`} />}

                    {Module.Choose_Listing_Page === "Available_Lots" && <AuctionTemplate auctionTag="available-lots" api_link={`${AuctionApi.available_lots}?endDate=${available_end_date}`} current_catalogue_api_link={`${AuctionApi.current_catalogue}`} />}

                    {Module.Choose_Listing_Page === "Current_Catalogue" && <AuctionTemplate auctionTag="current-catalogue" api_link={`${AuctionApi.current_catalogue}`} />}
                  </>
                }
              </>
            )
          })
        }

      {
        popupType && 
        <Modal
          id="popup_modal_form"
          show={isOpen}
          onHide={() => setIsOpen(false)}
          backdrop="static"
          keyboard={false}
          className="member-contact-modal"
      > 
          {
            popupType === "Member_Form" && <>
              <Modal.Header closeButton className="contact-close-btn memberPopup">
              <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">Contact - {membDet.Name}</h3></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <MemberForm to_email_id={membDet.Email_ID} to_user_id={membDet.Form_Queue_ID} formName={`Individual Contact - ${membDet.Name}`} formLabel={`Person ${membDet.Name}`} />
              </Modal.Body>
            </>
          }          
        </Modal>
      }

    </Layout>
  )
}

export default CommonTemplate